import React, { useContext, useState, useEffect } from "react"
import calibrationParameter from "./calibrationParameter"
import { GlobalStateContext } from "./GlobalState"
import SoundGenerator from "./SoundGenerator"
import style from './dialogStyle.module.css';


let rhythmMan = new SoundGenerator()
const hint = "Listen carefully to the pulse..."
const youNeed = "You will need to tap the screen trying to be perfectly syncronized with the pulse"
const theMore = "the more accurate you are, the more accurate the app will be"
const tap = "tap the button"
const rationale = "Due to a variety of devices available, all with different latencies, your device must be calibrated"
const repeatCalibration = "It seems you have been unconsistent in your tapping, do you want to repeat the calibration?"
export default function Calibration(props) {
    const [globalState, setGlobalState] = useContext(GlobalStateContext);
    const [phase, setPhase] = useState(0)
    const [drumHits, setdrumHits] = useState([])
    const [beats, setBeats] = useState([])
    const [message, setMessage] = useState(rationale)
    const [tareData, setTareData] = useState()


    function startMetronome60bpm(event, numberOfBeats) {
        document.querySelector("body").onkeydown = () => {

            window.tareHit()
        }
        const beatsArray = rhythmMan.startMetronomeLoop(100)
        setBeats(beatsArray)
        setPhase(phase + 1)
        setMessage(hint)
        console.log(rhythmMan.plannedSounds)
        rhythmMan.plannedSounds[4].onended = () => {
            setMessage(youNeed)
        }
        rhythmMan.plannedSounds[8].onended = () => {
            setMessage(theMore)
        }
        rhythmMan.plannedSounds[12].onended = () => {
            skipIntro()


        }



    }

    function skipIntro() {
        setPhase(2)
        setMessage(tap)

        rhythmMan.plannedSounds[4].onended = () => {

        }
        rhythmMan.plannedSounds[8].onended = () => {

        }
        rhythmMan.plannedSounds[12].onended = () => {

        }
    }
    let mean, sd, median

    function saveCalibration() {

        console.log("save calibration", tareData)

        if (tareData.sd <= 0.05 && typeof tareData.median === "number") {
            console.log("saveCalibration , meidan", tareData.median)
            let taredGlobalState = { ...globalState, tare: tareData.median, isFirstCalibrationDone: true }
            setGlobalState(taredGlobalState)
            localStorage.setItem("globalState", JSON.stringify(taredGlobalState));
            if (typeof props.setIsOpen === "function") {
                props.setIsOpen(false)
            }

        }
    }
    window.stopMetronomeSounds = stopMetronomeSounds

    function stopMetronomeSounds() {


        rhythmMan.stopSounds()
        setdrumHits([])
        setBeats([])
        setPhase(0)

        setMessage(rationale)
    }
    function drumHit() {
if(phase===2){
        if (drumHits.length <= 10) {
            const hitTime = rhythmMan.now()
            setdrumHits([...drumHits, hitTime])
            console.log(drumHits)
            let msg = drumHits.length >= 0 ? tap + " " + (10 - drumHits.length) + " more times" : tap
            setMessage(msg)
        }
        if (drumHits.length === 10) {
            [mean, sd, median] = calibrationParameter(beats, drumHits)
            mean = mean.toFixed(2)
            sd = sd.toFixed(2)
            median = median.toFixed(2)
            console.log(sd)
            setTareData({ sd: parseFloat(sd), mean: parseFloat(mean), median: parseFloat(median) })
            showCalibrationOutCome()
        }
    }
    }
    window.tareHit = drumHit
    function showCalibrationOutCome() {
        setPhase(3)
        rhythmMan.stopSounds()
        let outcomeMessage = sd < 0.05 ? "Calibration completed. A " + median + " seconds delay will be applied" : "Your tapping was unconsistent, we suggest you to repeat calibration"
        let outcomeData = "median delay : " + median + " | standard deviation delay: " + sd + "."
        setMessage(outcomeData + " " + outcomeMessage)

    }
    var output = <></>
    switch (phase) {
        case 0:


            output = <button className={style.saveButton} onClick={startMetronome60bpm}>Start Calibration</button>

            break;
        case 1: output = <>

            <button className={style.saveButton} onClick={stopMetronomeSounds}>Stop Calibration</button>
            <button className={style.saveButton} onClick={stopMetronomeSounds}>Restart</button>
            <button className={style.saveButton} onClick={skipIntro}>Skip intro</button>
            <div className={style.calibrationUnactiveDrumButton} >Tap here</div>
        </>

            break;
        case 2:
            output = <>
                <button className={style.dialogButton} onClick={stopMetronomeSounds}>Stop Calibration</button>
                <button className={style.dialogButton} onClick={stopMetronomeSounds}>Restart</button>
                <div className={style.calibrationDrumButton} onMouseDown={drumHit}>Tap here</div>
            </>
            break;
        case 3:
            output = <>
                <button className={style.dialogButton} onClick={saveCalibration}>Save Calibration</button>
                <button className={style.dialogButton} onClick={stopMetronomeSounds}>Restart</button>
                <div className={style.calibrationUnactiveDrumButton} >Drum</div>
            </>
            break;
        default:
            break;
        // code block
    }
    const savedGlobalState = JSON.parse(localStorage.getItem("globalState")) === null ? { isFirstCalibrationDone: false } : JSON.parse(localStorage.getItem("globalState"))
    let firstCalibrationMessage = savedGlobalState.isFirstCalibrationDone ? "This isn't the first calibration" : "This is the first calibration"
    return (
        <>

            <h3>Calibration</h3>
            
            <div className="calibrationMessages">{message}</div>
            <>
                {output}

            </>


        </>
    )
}

