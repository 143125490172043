import React, {useState, useContext } from "react"
import { GlobalStateContext } from "./GlobalState"
import ContentTabs from './ContentTabs'
import NavMenu from "./NavMenu"
import Dialog from "./Dialog"

export default function SlideNavigation({ tabs }) {
    const [globalState, setGlobalState] = useContext(GlobalStateContext);
    const [isCalibrationOpen,setCalibrationOpen]=useState(true)
    let output =  <></> 
  
    if (!globalState.isFirstCalibrationDone){
        console.log("open")
        if (isCalibrationOpen===true) {
            console.log("open")
            output= <Dialog isOpen={isCalibrationOpen}  setIsOpen={setCalibrationOpen}/>
        }
    }
    
    return (
        <>
            <NavMenu tabs={tabs} globalState={useContext(GlobalStateContext)} />
            <ContentTabs />
            {output}


        </>
    )
}
/* come fare il tasto per chiudere la calibrazione? uso il global state collegato ad un tasto? */