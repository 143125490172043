import React, { useContext, useState } from 'react'
import uuidv4 from 'uuid/v4'
import { GlobalStateContext } from "./GlobalState"
import moment from 'moment'
import Credits from "./Credits"
import StarRatings from 'react-star-ratings';
import style from './statsStyle.module.css';
import styleDialog from './dialogStyle.module.css';
window.moment = moment
export default function ContentTabs() {
    const [globalState, setGlobalState] = useContext(GlobalStateContext);
    
    var statsKeys = Object.keys(window.localStorage).sort().reverse()
    const [stats, setStats] = useState(statsKeys.map((key, index) => {
        if (key !== "globalState") {
            var data = JSON.parse(window.localStorage.getItem(key.toString()))
            var numberOfCorrect = 0
            for (let index = 0; index < data.outcome.length; index++) {
                const element = data.outcome[index];
                if (element.isCorrect) { numberOfCorrect++ }
            }
            var d = new Date(0); d.setUTCSeconds(key)
            var date = moment(d).format('DD/MM/YY')
            var time = moment(d).format('hh:mm')
            var levels= typeof data.levels==="string"?data.levels:"-"
            var su = data.outcome.length !== 0 ? data.outcome.length : 1
            var stars = numberOfCorrect / su * 5
            stars = typeof stars === "number" ? stars : 0
            

            return <tr key={uuidv4()} className={style.tableRow}>

                <td className={style.tableData}>{date}</td>
                <td className={style.tableData}>{time}</td>
                <td className={style.tableData}>{levels}</td>

                <td className={style.tableData}>{numberOfCorrect + "/" + su}
                    <StarRatings
                        rating={stars}
                        starDimension='20px'
                        name='rating'
                        starSpacing="0px"
                        starRatedColor="#f7b02f"


                    /></td>
            </tr>


        }
    })

    )
    function cleanStats() {
        var chacheState = localStorage.getItem("globalState")
        localStorage.clear()
        if (chacheState != null) {
            localStorage.setItem("globalState", chacheState)
        }

        setStats(<></>)
    }



    return (
        <>

            <table key={"ra123324"} className={style.statsTable}>
                <thead >
                    <tr>
                    <th className={style.tableHeader}>Date</th>
                    <th className={style.tableHeader}>Time</th>
                    <th className={style.tableHeader}>Levels</th>
                    <th className={style.tableHeader}>Result</th></tr>
                </thead>
                <tbody>
                {stats}
                </tbody>
                

            </table>
            <button className={styleDialog.saveButton} onClick={cleanStats}>Clean Stats</button>
            <Credits/>
        </>

    )
}
