//generate an array of different random integers array

export default function RandomIntsArray(min, max, length) {
    let randomIntsArray = []
    let numberOfRepetitions=Math.floor(length/max)
    //console.log(numberOfRepetitions)
    while (randomIntsArray.length < length) {
        let randomNumber = Math.floor((Math.random() * max))
        //se non presente nell'array e maggiore di min inserisci nell'array
        let chunkIndex=Math.floor(randomIntsArray.length/max)
        
            if (getOccurrencesOfElementInArray(randomIntsArray,randomNumber) < chunkIndex+1) {
                randomIntsArray.push(randomNumber)

            }
        

    }
    //console.log(randomIntsArray)
    return randomIntsArray

}

function getOccurrencesOfElementInArray(array, value) {
    return array.filter((v) => (v === value)).length;
}
window.RandomIntsArray = RandomIntsArray
window.getOccurrencesOfElementInArray=getOccurrencesOfElementInArray
