import IntervalCalculatorFromBpm from "./IntervalCalculatorFromBpm"
import SoundFiles from "./SoundFiles"
import HowIsThis16thPlayed from "./HowIsThis16thPlayed"
import countToLed from './CountToLed'
import createBanner from './createBanner';
//pausa tra le configurazioni: non si può cambiare durante l'allenamento?
/* setter per il suono d'aiuto:
    1. se in moto, metti pausa. Cambia impostazione e avvia

*/
class RhythmManager {
    constructor(bpm, metronomeType, configurations, setLedSettings, parent) {
        this.nextConfiguration = parent.nextConfiguration
        this.previousConfiguration = parent.previousConfiguration

        this._bpm = bpm
        this.parent = parent
        this.metronomeType = metronomeType
        this.configurations = configurations


        this.ledCounter = -1
        this.sixteenthDuration = IntervalCalculatorFromBpm(this.bpm)
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.ledCounter = 0
        this.setLedSettings = setLedSettings
        this._helpSound = this.parent.props.globalState.helpSound
        this.drumRecording = false
        this.muteSoundsArray = []
        this.bigTicsArray = []
        this.smallTicsArray = []
        this.helpTicArray = []
        this.playingOptionArray = []
        this.played16thArray = []
        this.soundMomentsArray = []
        this.transitionSoundsArray = []

        this.soundFiles = new SoundFiles(this.audioContext,
            [
                "/ritmo/media/Suoni/quarti.mp3",
                "/ritmo/media/Suoni/ottsedic.mp3",
                "/ritmo/media/Suoni/suonoaiuto.mp3",
                "/ritmo/media/Suoni/tamburo.mp3",
                "/ritmo/media/Suoni/mute.mp3",
                "/ritmo/media/Suoni/suonoriposo.mp3"
            ]);



    }
    get helpSound() {
        return this._helpSound
    }
    set helpSound(x) {
        if (this.parent.runningState === "active") {
            this.pauseResume();
            this._helpSound = x;
            this.pauseResume();
        } else {
            this._helpSound = x;
        }

    }
    get bpm() {
        return this._bpm
    }
    set bpm(x) {
        this._bpm = x;
        this.sixteenthDuration = IntervalCalculatorFromBpm(this.bpm)
    }
    callBackEvery16thBeforeTic(momentOfPLayed16th, sixteenth) {
        //console.log("callBackEvery16thBeforeTic fired")
        //console.log(sixteenth,"<- sixteenth")
        var delay = 1000 * (momentOfPLayed16th - this.now())
        if (sixteenth === this.parent.props.globalState.sixteenthOfAnimation) {
            //console.log("running animation fired")
            document.documentElement.style.setProperty('--heandsAnimationDelay', this.sixteenthDuration * 2 + "s");
            document.documentElement.style.setProperty('--heandsAnimationDuration', this.sixteenthDuration * 2 + "s");
            this.parent.props.setGlobalState({ ...this.parent.props.globalState, heandClass: "heandsWrapper heandsAnimation" })

        }
        this.ledCounter++
        var ledId = countToLed(this.ledCounter)
        setTimeout(() => {
            for (let index = 0; index < 4; index++) {
                if (index === ledId) {
                    document.documentElement.style.setProperty("--animationLed" + index, "ledAnimation 90ms 1");
                } else {
                    document.documentElement.style.setProperty("--animationLed" + index, "ledAnimationNull 90ms 1");
                }

            }
            //this.setLedSettings("ledTicEven " + this.ledCounter)
        }, delay)



    }
    drumManager(momentOfPLayed16th, sixteenth) {
        var delay = 1000 * (momentOfPLayed16th - this.now())


    }
    volumeController(soundNumber) {

    }
    now() {
        return this.audioContext.currentTime
    }



    start(beginningIndex, configurationsIndex, beginningMoment, previousConfIsCorect) {
        // reset outcome records when doing a configuration
        this.parent.listOfDrumHits[configurationsIndex] = []
        //
        var soundMoment
        if (typeof beginningMoment === "number") {
            soundMoment = beginningMoment
        } else {
            soundMoment = this.now()
        }

        //accende il led al primo rintocco del metronomo
        if (this.metronomeType != "none") {
            this.callBackEvery16thBeforeTic(soundMoment)
        }
        window.m.runningState = "active"

        //patch suono ad una mano
        /*if (this.configurations[configurationsIndex].length > 4) {
            this.parent.setGlobalState({ ...this.parent.props.globalState, total16ths: 32 })
            this.total16th = 32
        } else {
            this.parent.setGlobalState({ ...this.parent.props.globalState, total16ths: 29 })
            this.total16th = 29
        }*/

        for (let index = beginningIndex; index < this.parent.props.globalState.total16ths; index++) {
            const playingOption = HowIsThis16thPlayed(index, this.metronomeType, this.configurations[configurationsIndex])

            this.soundMomentsArray[index] = soundMoment
            //mute rythm
            this.muteSoundsArray[index] = this.soundPlan(4, soundMoment)
            // add 16th counter
            this.muteSoundsArray[index].onended = () => {
                this.parent.sixteenthCounter = index

            }
            this.playingOptionArray[index] = playingOption

            if (playingOption.metronomeSound !== "none" && index > beginningIndex) {
                //led call back
                this.played16thArray[index] = index

                this.muteSoundsArray[index - 1].onended = () => {
                    this.parent.sixteenthCounter = index
                    this.callBackEvery16thBeforeTic(this.soundMomentsArray[index], index)

                }
            }
            //workaround per l'interrruzione tra trials
            /*index indica il 16th
            se index è 0
            */
            //big metronome sound
            var bigTicSoundIndex = 0//if it's the first tic of trial is muted

            if (playingOption.metronomeSound === "big") {
                this.bigTicsArray.push(this.soundPlan(bigTicSoundIndex, soundMoment))
                //small metronome sound
            } else if (playingOption.metronomeSound === "small") {
                this.smallTicsArray.push(this.soundPlan(1, soundMoment))

            }

            if (playingOption.isHelpSoundPlayed && this.helpSound === "active" && index >= this.parent.props.globalState.sixteenthOfBeginningExecution) {
                this.helpTicArray.push(this.soundPlan(2, soundMoment))

            }
            soundMoment = soundMoment + this.sixteenthDuration

        }

        var lastClickMoment = soundMoment
        //patch suono ad una mano
        try {
            if (this.configurations[configurationsIndex].length === 4) {
                var orderedSounds = [...this.configurations[configurationsIndex]]
                var beginning = orderedSounds.sort().reverse().indexOf("0")
                beginning = beginning == -1 ? 4 : beginning //risolve il bug per quando tutte le dita sono alzate

                if (beginning >= 0) {
                    for (let index = beginning; index < this.helpTicArray.length; index++) {
                        if (this.helpTicArray[index].stop() != undefined) {
                            this.helpTicArray[index].stop()
                        }

                    }

                }

            }
        } catch (error) {
            console.error(error);
            // expected output: ReferenceError: nonExistentFunction is not defined
            // Note - error messages will vary depending on browser
        }
        //patch for metroneme in transition
        if (/*if it's not the last trial*/ configurationsIndex !== this.configurations.length - 1) {


            this.transitionSoundsArray.push(this.soundPlan(5, lastClickMoment))
            this.transitionSoundsArray.push(this.soundPlan(5, lastClickMoment + (this.sixteenthDuration * 4)))

        }
        /*
        alla fine del penultimo quarto
        programma 2 click
        */



        this.muteSoundsArray[this.muteSoundsArray.length - 1].onended = () => {
            this.setLedSettings("reset123 " + this.ledCounter)
            
            
            this.parent.props.setGlobalState({ ...this.parent.props.globalState, heandClass: "heandsWrapper" })
            setTimeout(() => {
               var isCorect = this.parent.drumScore()

                if (configurationsIndex < this.configurations.length - 1 && this.parent.runningState !== "paused") {

                    this.parent.nextConfiguration((lastClickMoment) + (this.sixteenthDuration * 8), isCorect)//da rivedere

                } else if (this.parent.runningState !== "paused") {
                    window.m.runningState = "unactive"
                    this.parent.saveOutcome()
                    this.stop(this.parent.props.globalState.combinationIndex)
                    this.sixteenthCounter = -1
                    this.parent.setGlobalState({ ...this.parent.props.globalState, combinationIndex: 0, sixteenthCounter: -1, currentOutcome: { outcome: [] } })

                    
                    console.log("configurazioni terminate")
                    window.m.resetConfiguration()
                }
            },400)
        }



    }
    soundPlan(soundIndex, moment) {
        var soundBuffer = this.audioContext.createBufferSource();
        soundBuffer.buffer = this.soundFiles.buffers[soundIndex]
        soundBuffer.connect(this.audioContext.destination)
        soundBuffer.start(moment)
        return soundBuffer
    }
    pauseResume() {

        if (this.parent.runningState === "active") {
            const current16th = this.parent.sixteenthCounter
            const currentLedIndex = this.ledCounter
            //patch for metroneme in transition
            for (let index = 0; index < this.transitionSoundsArray.length; index++) {
                if (this.transitionSoundsArray[index].playbackState===undefined || this.transitionSoundsArray[index].playbackState===1) {
                  this.transitionSoundsArray[index].stop()  
                }
                
            }
            //end of patch
            this.stop(this.parent.props.globalState.combinationIndex)
            this.parent.sixteenthCounter = current16th
            this.ledCounter = currentLedIndex
            this.parent.runningState = "paused"
            createBanner();
        } else if (this.parent.runningState === "paused") {

            this.start(this.parent.sixteenthCounter, this.parent.props.combinationIndex)
            this.parent.runningState = "active"

        } else {
            console.error("you can not pause when it is unactive")
        }
    }

    drumSoundPlay() {

        const drum = this.soundPlan(3, 0)

    }
    stop(combinationIndex) {
        for (let index = 0; index < this.bigTicsArray.length; index++) {
            this.bigTicsArray[index].stop()
        }
        for (let index = 0; index < this.smallTicsArray.length; index++) {
            this.smallTicsArray[index].stop()
        }
        for (let index = 0; index < this.muteSoundsArray.length; index++) {
            if (typeof this.muteSoundsArray[index] != "undefined") {
                this.muteSoundsArray[index].onended = () => { }
                this.muteSoundsArray[index].stop()
            }

        }
        let length_patch=this.configurations[combinationIndex].length === 4?this.helpTicArray.length/2:this.helpTicArray.length
        console.log("lunghezza array",length_patch,this.helpTicArray.length)
        for (let index = 0; index < length_patch; index++) {
            
            if (typeof this.helpTicArray[index] != "undefined") {
                try {
                    this.helpTicArray[index].stop()
                } catch(error){
                    console.error(error,index,this.helpTicArray[index])

                }
                
            }
        }


        this.setLedSettings("reset123 " + this.ledCounter)
        this.parent.props.setGlobalState({ ...this.parent.props.globalState, heandClass: "heandsWrapper" })
        this.ledCounter = 0;
        this.parent.sixteenthCounter = -1;
        this.muteSoundsArray = []
        this.bigTicsArray = []
        this.smallTicsArray = []
        this.helpTicArray = []
        this.playingOptionArray = []
        this.played16thArray = []
        this.soundMomentsArray = []

    }



}


export default RhythmManager;