


export default function createBanner() {
    sessionStorage.setItem("ads-counter", parseInt(sessionStorage.getItem("ads-counter")) + 1)

    parseInt(sessionStorage.getItem("ads-counter")) < 3 && loadAd()

}
function loadAd() {
    // Create a script tag, set its source
    var scriptTag = document.createElement("script"),
        filePath = "/ritmo/banner.js";

    // And listen to it
    scriptTag.onload = function (loadEvent) {
        // This function is an event handler of the script tag
        handleEvent();
    }



    // Set the type of file and where it can be found
    scriptTag.type = "text/javascript";
    scriptTag.src = filePath;
    var adDiv = document.querySelector("#ads");
    var closeButtonTag = document.createElement("button");
    closeButtonTag.onclick = closeAd;
    closeButtonTag.innerHTML = "X"

    adDiv.appendChild(closeButtonTag);


    adDiv.appendChild(scriptTag);

    function closeAd() { adDiv.innerHTML = "" }
}

function handleEvent() {
    console.log('The file has been loaded. Do something else.');
    // More code here
}
window.loadAd = loadAd

