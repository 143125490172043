import React, { Component, useContext } from 'react'
import { GlobalStateContext } from "./GlobalState"
import Metronome from './Metronome';
var dictionary = {
    training: "Practice",
    test: "Test",
    options: "Options",
    stats: "Stats"
}

class NavMenu extends Component {


    constructor(props) {
        super(props);
        this.onLiClick = this.onLiClick.bind(this);

        this.selectionElement = React.createRef();
        this.initialSelectedElement = React.createRef();
        this.navList = React.createRef();
        const [globalState, setGlobalState] = props.globalState
        this.props = props
        this.globalState = globalState
        this.setGlobalState = setGlobalState
        this.state = {
            selectedTab: dictionary[this.globalState.navigation],
        };
    }

   
   
    componentDidMount() {

        const menuElements = this.navList.current.children;
        const destination = typeof localStorage.getItem("globalState")==="string"?JSON.parse(localStorage.getItem("globalState")).navigation:"training"
        
        


    }

    onLiClick(e) {
     
        var newState = { ...this.props.globalState[0] }
        const currentNavigation = newState.navigation
        const metronomeSate = window.m.runningState
        const destination = e.target.attributes.type.value
        if (currentNavigation != destination) {
            if (window.m.runningState !== "unactive") {
                if (currentNavigation === "training" || currentNavigation === "test") {
                    if (/* destination === "training" || destination === "test" */true) {
                        window.m.fermaMetronomo()
                    }
                }

            }
        }


        setTimeout(() => {

            newState = { ...this.props.globalState[0] }
            if (destination === "training" || destination === "test") {
                newState = { ...newState, mode: destination }


            }
            newState = { ...newState, navigation: destination }

            this.props.globalState[1]({ ...newState })
        }, 50)

    }



    render() {
        return (
            <div className="navMenuContainer">
                <ul className="navMenu" ref={this.navList} >
                    
                    {this.props.tabs.map(tab => {
                        let classNameLocal= this.props.globalState[0].navigation===tab.type?"selectedTab":"unselectedTab"
                       
                        return (<li key={tab.name} id={tab.name} type={tab.type} className={classNameLocal}>
                            <span
                                type={tab.type}

                                onClick={(e) => this.onLiClick(e)}
                            >
                                {tab.name}
                            </span>
                        </li>)
                    })}
                </ul>
            </div>
        )


    }
}

export default NavMenu
