
import SoundFiles from "./SoundFiles"


class SoundGenerator {
    constructor() {

        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();

        this.plannedSounds = []
        this.plannedTimes = []
        this.soundFiles = new SoundFiles(this.audioContext,
            [
                "/ritmo/media/Suoni/quarti.mp3",

            ]);



    }

    now() {
        return this.audioContext.currentTime
    }
    stopSounds() {
        console.log(this.plannedSounds)


        for (let index = 0; index < this.plannedSounds.length; index++) {

            if (this.plannedSounds[index].playbackState === undefined || this.plannedSounds[index].playbackState === 1) {
                this.plannedSounds[index].stop()
            }

        }
        this.plannedSounds = []
        this.plannedTimes = []
    }


    startMetronomeLoop(length) {
        this.plannedSounds = []
        this.plannedTimes = []
        let now = this.now()
        let timesArray = []
        for (let index = 0; index < length; index++) {
            let currentTime = now + (1 * index)
            this.plannedSounds.push(this.soundPlan(0, currentTime))
            timesArray.push(currentTime)
        }
        console.log(this.plannedSounds)
        return timesArray
    }
    soundPlan(soundIndex, moment) {
        var soundBuffer = this.audioContext.createBufferSource();
        soundBuffer.buffer = this.soundFiles.buffers[soundIndex]
        soundBuffer.connect(this.audioContext.destination)
        soundBuffer.start(moment)
        return soundBuffer
    }

}


export default SoundGenerator;