import React, { useState,useEffect } from "react"
import style from './dialogStyle.module.css';
import Calibration from "./Calibration"
export default function Credits(props) {
   


    return (
        <>
                    <ul className="credits">
                        <li><span>Created by </span><span>Michele Braguti</span></li>
                        <li><span>Developed by </span><span>Salvatore Isaia</span></li>
                        <li><span>Ethic and Aesthetics by </span><span>Rosario Galano</span></li>
                       
                        
                    </ul>
                


        </>
    )
    }