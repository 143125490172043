import React, { useState,useEffect } from "react"
import style from './dialogStyle.module.css';
import Calibration from "./Calibration"
export default function Dialog(props) {
    const [state, setState] = useState("welcome")
    let output
    
    const close=()=>{
        props.setIsOpen(false)
        console.log("close")
        window.stopMetronomeSounds()
    }
   
    if (state === "welcome") {
        output =
            <div>
                <h2>Welcome to FingerRitmo!</h2>
                <div>Due to a variety of devices aviable, all with different latencies, your devices must be calibrated</div>
                
                    <button>Start Calibration</button>

                    <button>Skip Calibration</button>
                </div>;}




    return (
        <>
                    <div className={style.dialogContainer}>
                        <div className={style.dialogWindow}>
                        <button className={style.dialogButton} onClick={close}>X</button>
                            <Calibration isOpen={props.isOpen} setIsOpen={ props.setIsOpen} />
                        </div>


                    </div>
                


        </>
    )
    }