import findClosestNumberInArray from "./findClosestNumber"

export default function calibrationParameter(arrayOfMetronomeBeats,arrayofDrumBeats){
    let arrayOfConsideredMetronomeBeats=[]
    for (let index = 0; index < arrayofDrumBeats.length; index++) {
        const element = arrayofDrumBeats[index];
        arrayOfConsideredMetronomeBeats.push(findClosestNumberInArray(arrayOfMetronomeBeats,element))
        
    }
    let arrayOfDeltas=[]
    for (let index = 0; index < arrayofDrumBeats.length; index++) {
        const drumBeat = arrayofDrumBeats[index];
        const metrBeat = arrayOfConsideredMetronomeBeats[index]
        arrayOfDeltas.push(drumBeat-metrBeat)
        
    }
    console.log("sd",standardDeviation(arrayOfDeltas))
    console.log("drum hits:", arrayofDrumBeats)
    console.log("metr hits:", arrayOfConsideredMetronomeBeats)
    console.log("deltas: ",arrayOfDeltas)
    
    return [median(arrayOfDeltas),standardDeviation(arrayOfDeltas),getAvg(arrayOfDeltas)]
}
function median(values){
    if(values.length ===0) return 0;
  
    values.sort(function(a,b){
      return a-b;
    });
  
    var half = Math.floor(values.length / 2);
  
    if (values.length % 2)
      return values[half];
  
    return (values[half - 1] + values[half]+values[half+1]) / 3.0;
  }
  const standardDeviation = (arr, usePopulation = false) => {
    const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
    return Math.sqrt(
      arr.reduce((acc, val) => acc.concat((val - mean) ** 2), []).reduce((acc, val) => acc + val, 0) /
        (arr.length - (usePopulation ? 0 : 1))
    );
  };
  
  function getAvg(grades) {
    const total = grades.reduce((acc, c) => acc + c, 0);
    return total / grades.length;
  }